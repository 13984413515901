import React from 'react';
import i18n from '../../i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { Button, IconButton, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, ListSubheader, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { DataLanding, DataTemperature, DataSoilTemperature, DataDewPoint, DataPrecipitation, DataRelativeHumidity, DataSolarRadiation, DataWind, DataSoilMoisture } from './Data';
import { ForecastLanding, ForecastSoilTemperature, ForecastPotentialET, ForecastReferenceET, ForecastET, ForecastPrecipitation, ForecastConvectivePrecipitation, ForecastSnow, ForecastPrecipitationHumidity, ForecastTemperatureHumidity, ForecastHumidityTemperature, ForecastSoilMoisture, ForecastPressure, ForecastHeatFlux, ForecastUVIndex, ForecastWind } from './Forecast';
import { FieldAccessibility, PlantNutrition, TillageAbility, SowingWindowGeneral, SowingWindowPotato, SowingWindowSugarbeet, SowingWindowCorn, SowingWindowCanola, SowingWindowSunflower, PlantProtectionGeneral, PlantProtectionTreecrop, PlantProtectionViticulture, PlantProtectionPotato, HarvestWindowFruitGrape, HarvestWindowSugarbeetPotato, HarvestWindowSmallCereals, HarvestWindowHaySillage } from './WorkPlanning';
import { styles } from './SensorStyles';
import { DataDeltaT } from './Data/DataDeltaT';
import { UniDisease } from './Disease/UniDisease';
import axios from '../../utils/AxiosWrapper';
import { wentWrong } from '../../utils/ToastConfig';

export class SensorDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstRender: true,
            currentPage: '', currentPageGroup: '', currentData: '',
            models: undefined
        };
    }

    async componentDidMount() {
        this.setState({ currentPage: 'data/temperature', currentPageGroup: 'temperature', currentData: 'data' });

        // await axios.get('api/testMeteo/station_information',{params:{ uid: this.props.row.uid}})
        //     .then(res => { 
        //         { this.setState({ models: res.data.data.licenses.models }) }
        //         console.log('models',this.state.models)
                
        //     })
        //     .catch(error => {
        //         wentWrong('Something went wrong, please try again!');
        //         console.log('Error 2 (getByURL): ', error);
        //     })
    }

    onTabClick = (data, page, pageGroup) => { this.setState({ currentData: data, currentPage: page, currentPageGroup: pageGroup }) };
    onPageClick = (page) => { this.setState({ currentPage: page }) };

    dataGroupHandler = (group) => {
        if (this.state.currentPageGroup !== group) this.setState({ currentPageGroup: group });
        else if (this.state.currentPageGroup === group) this.setState({ currentPageGroup: '' });
    };

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    render() {
        let user = this.getUser();

        const { classes } = this.props;
        return (
            <div style={{ padding: 10 }}>
                <Dialog PaperProps={{ style: { maxHeight: '100%' } }} open={this.props.open} onClose={this.props.handleClick}
                    fullWidth={true} disableBackdropClick maxWidth="lg" aria-labelledby="responsive-dialog-title2">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e'}} className="responsive-dialog-title2">
                        <div style={{display:'flex', flexDirection:'column',flexShrinkhrink:'0', maxwidth:'190px'}}>
                        <p>
                        {i18n.t("Meteo station")}: {this.props.row.naziv}
                        </p>
                        </div>
                        <div style={{ display: 'flex', alignSelf: 'left'}}>
                            <Button onClick={() => { this.onTabClick('data', 'data/temperature', 'temperature'); }} className={this.state.currentData === 'data' ? classes.pressedTab : classes.tab}>{i18n.t('Meteo data')}</Button>
                            <Button onClick={() => { this.onTabClick('forecast', 'forecast/soil_temperature', 'temperature'); }} className={this.state.currentData === 'forecast' ? classes.pressedTab : classes.tab}>{i18n.t('Weather forecast')}</Button>
                            {/* 10 Stanica ciji uid-evi pocinju sa '00212' nemaju uslugu disease, zato se dugme sakriva kod njih */}
                            {this.props.row.uid.substring(0,5) !== '00212' && <Button onClick={() => { this.onTabClick('disease', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                             {/* ukoliko stanica nema otkljucane modele bolesti  dugme se sakriva kod njih */}
                            {/* {this.state.models !== undefined && <Button onClick={() => { this.onTabClick('disease', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> } */}
                            {this.props.row.uid === '00212BE5' && <Button onClick={() => { this.onTabClick('diseasevukoje', 'viticulture/blackrot', 'viticulture'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '00212BE6' && <Button onClick={() => { this.onTabClick('diseasevukoje', 'viticulture/blackrot', 'viticulture'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '00212BE7' && <Button onClick={() => { this.onTabClick('diseasevukoje', 'viticulture/blackrot', 'viticulture'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '002122E0' && <Button onClick={() => { this.onTabClick('diseasevivagro', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '002122DF' && <Button onClick={() => { this.onTabClick('diseasevivagro', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '002122F4' && <Button onClick={() => { this.onTabClick('diseasevivagro', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            {this.props.row.uid === '002127A2' && <Button onClick={() => { this.onTabClick('diseaseskugricanka', 'wheat/fusariumheadblight', 'wheat'); }} className={this.state.currentData === 'disease' ? classes.pressedTab : classes.tab}>{i18n.t('Disease and pests')}</Button> }
                            <Button onClick={() => { this.onTabClick('workplanning', 'sowingwindow/general', 'sowingwindow'); }} className={this.state.currentData === 'workplanning' ? classes.pressedTab : classes.tab}>{i18n.t('Work planning')}</Button>
                            <IconButton onClick={this.props.handleClick} style={{ marginLeft: '300px' }}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ padding: '0px', backgroundColor: '#f7f7f7', overflow: 'hidden' }}>
                        <div>
                            {this.state.currentData === 'data' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                                            <ListItem button onClick={() => { this.dataGroupHandler('temperature'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Temperature general')} />
                                                {this.state.currentPageGroup === 'temperature' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'temperature'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='data/temperature' title={i18n.t('Temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='data/soil_temperature' title={i18n.t('Soil temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='data/dew_point' title={i18n.t('Dew point')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='data/solar_radiation' title={i18n.t('Solar radiation')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='data/deltat' title={i18n.t('Delta T')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('precipitation'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Precipitation')} />
                                                {this.state.currentPageGroup === 'precipitation' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'precipitation'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='data/precipitation' title={i18n.t('Precipitation and leaf wetness')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('humidity'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Humidity')} />
                                                {this.state.currentPageGroup === 'humidity' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'humidity'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='data/relative_humidity' title={i18n.t('Relative humidity')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {
                                                        user.id_region !== 2 &&
                                                        <ListItemSyled page='data/soil_moisture' title={i18n.t('Soil moisture')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('wind'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Wind')} />
                                                {this.state.currentPageGroup === 'wind' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'wind'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='data/wind' title={i18n.t('Wind speed and direction')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>


                                        </List>
                                    </div>
                                    {this.state.currentPage === 'data/info' && <div className={classes.page}> <DataLanding row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'data/temperature' && <div className={classes.page}> <DataTemperature row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'data/soil_temperature' && <div className={classes.page}> <DataSoilTemperature row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'data/dew_point' && <div className={classes.page}> <DataDewPoint row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'data/deltat' && <div className={classes.page}> <DataDeltaT row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'data/precipitation' && <div className={classes.page}> <DataPrecipitation row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'data/relative_humidity' && <div className={classes.page}> <DataRelativeHumidity row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'data/soil_moisture' && <div className={classes.page}> <DataSoilMoisture row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'data/solar_radiation' && <div className={classes.page}> <DataSolarRadiation row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'data/wind' && <div className={classes.page}> <DataWind row={this.props.row} /> </div>}


                                </div>
                            }
                            {this.state.currentData === 'forecast' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                                            <ListItem button onClick={() => { this.dataGroupHandler('temperature'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Temperature general')} />
                                                {this.state.currentPageGroup === 'temperature' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'temperature'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    {/* <ListItemSyled page='forecast/felt_temperature' title={i18n.t('Felt temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                    <ListItemSyled page='forecast/soil_temperature' title={i18n.t('Soil temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='forecast/dew_point' title={i18n.t('Dew point')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                    <ListItemSyled page='forecast/temperature_humidity' title={i18n.t('Temperature and humidity')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('precipitation'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Precipitation')} />
                                                {this.state.currentPageGroup === 'precipitation' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'precipitation'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/precipitation' title={i18n.t('Precipitation and leaf wetness')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/convective_precipitation' title={i18n.t('Convective precipitation')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/precipitation_humidity' title={i18n.t('Precipitation and humidity')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/snow' title={i18n.t('Snow')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('humidity'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Humidity')} />
                                                {this.state.currentPageGroup === 'humidity' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'humidity'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/humidity_temperature' title={i18n.t('Humidity and temperature')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='forecast/soil_moisture' title={i18n.t('Precipitation')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                </List>
                                            </Collapse>
                                            {/* <ListItem button onClick={() => { this.dataGroupHandler('evapotranspiration'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Evapotranspiration')} />
                                                {this.state.currentPageGroup === 'evapotranspiration' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem> */}
                                            <Collapse in={this.state.currentPageGroup === 'evapotranspiration'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/evapotranspiration' title={i18n.t('Evapotranspiration')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='forecast/potential_evapotranspiration' title={i18n.t('Potential ET')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/reference_evapotranspiration' title={i18n.t('Reference ET')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('wind'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Wind')} />
                                                {this.state.currentPageGroup === 'wind' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'wind'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/wind' title={i18n.t('Wind speed and direction')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('pressure'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Pressure')} />
                                                {this.state.currentPageGroup === 'pressure' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'pressure'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/pressure' title={i18n.t('Sea level pressure')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('other'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Other')} />
                                                {this.state.currentPageGroup === 'other' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'other'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='forecast/heat_flux' title={i18n.t('Sensible heat flux')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='forecast/uv_index' title={i18n.t('UV Index')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                        </List>
                                    </div>
                                    {/* {this.state.currentPage === 'forecast/felt_temperature' && <div className={classes.page}> <ForecastFeltTemperature row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/dew_point' && <div className={classes.page}> <ForecastDewPoint row={this.props.row} /> </div>} */}
                                    {this.state.currentPage === 'forecast/info' && <div className={classes.page}> <ForecastLanding row={this.props.row} /> </div>}
                                    
                                    
                                    {this.state.currentPage === 'forecast/temperature_humidity' && <div className={classes.page}> <ForecastTemperatureHumidity row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/soil_temperature' && <div className={classes.page}> <ForecastSoilTemperature row={this.props.row} /> </div>}


                                    {this.state.currentPage === 'forecast/precipitation' && <div className={classes.page}> <ForecastPrecipitation row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/convective_precipitation' && <div className={classes.page}> <ForecastConvectivePrecipitation row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/precipitation_humidity' && <div className={classes.page}> <ForecastPrecipitationHumidity row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/snow' && <div className={classes.page}> <ForecastSnow row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'forecast/humidity_temperature' && <div className={classes.page}> <ForecastHumidityTemperature row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/soil_moisture' && <div className={classes.page}> <ForecastSoilMoisture row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'forecast/evapotranspiration' && <div className={classes.page}> <ForecastET row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/potential_evapotranspiration' && <div className={classes.page}> <ForecastPotentialET row={this.props.row} /> </div>}
                                    {/* {this.state.currentPage === 'forecast/reference_evapotranspiration' && <div className={classes.page}> <ForecastReferenceET row={this.props.row} /> </div>} */}

                                    {this.state.currentPage === 'forecast/wind' && <div className={classes.page}> <ForecastWind row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'forecast/pressure' && <div className={classes.page}> <ForecastPressure row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/heat_flux' && <div className={classes.page}> <ForecastHeatFlux row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'forecast/uv_index' && <div className={classes.page}> <ForecastUVIndex row={this.props.row} /> </div>}
                                </div>
                            }
                            {this.state.currentData === 'disease' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                                    {i18n.t('Crops')}
                                                </ListSubheader>
                                            }>
                                            <ListItem button onClick={() => { this.dataGroupHandler('wheat'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Wheat and Barley')} />
                                                {this.state.currentPageGroup === 'wheat' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'wheat'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='wheat/fusariumheadblight' title={i18n.t('Wheat Fusarium Head Blight (Fusarium spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/aphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />

                                                    <ListItemSyled page='wheat/septorianodorum' title={i18n.t('Leaf blotch Septoria nodorum (Septoria nodorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/septoriatritici' title={i18n.t('Septoria Tritici blotch (Septoria tritici)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/pucciniaspp' title={i18n.t('Rust Puccinia spp. (Puccinia spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/powderymildew' title={i18n.t('Wheat Powdery Mildew (Blumeria graminis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/drechslerateres' title={i18n.t('Drechslera Teres (Pyrenophora teres)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='wheat/generaldrechsleragraminea' title={i18n.t('Barley stripe/D. graminea (Pyrenophora graminea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    <ListItemSyled page='wheat/ramularia' title={i18n.t('Ramularia (Ramularia collo-cygni)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='wheat/takeallgaeumannomycesgraminis' title={i18n.t('Take all Gaeumannomyces graminis (Gaeumannomyces graminis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('corn'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Corn')} />
                                                {this.state.currentPageGroup === 'corn' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'corn'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='corn/fusariumheadblight' title={i18n.t('Fusarium Head Blight Corn (Fusarium spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='corn/leafblighthelminthosporiumturcicum' title={i18n.t('Leaf Blight Helminthosporium turcicum (Helminthosporium turcicum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='corn/greyleafspotcercosporasp' title={i18n.t('Grey Leaf Spot Cercospora sp. (Cercospora zeae-maydis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('soya'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Soya')} />
                                                {this.state.currentPageGroup === 'soya' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'soya'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='soya/generalsclerotinia' title={i18n.t('Stem rot Sclerotinia sclerotiorum (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('sugar beet'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Sugar Beet')} />
                                                {this.state.currentPageGroup === 'sugar beet' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'sugar beet'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='sugarbeet/cercospora' title={i18n.t('Leaf spot (Cercospora beticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('rapseed'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Rapseed / Canola')} />
                                                {this.state.currentPageGroup === 'rapseed' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'rapseed'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='rapseed/pollenbeetleflight' title={i18n.t('Pollen beetle flight (Meligethes aeneus)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='rapseed/stemrotsclerotiniasclerotiorum' title={i18n.t('Stem rot Sclerotinia sclerotiorum Rapeseed (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='rapseed/phomastemcancer' title={i18n.t('Phoma Stem cancer (Leptosphaeria maculans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                               </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('apple'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Apple')} />
                                                {this.state.currentPageGroup === 'apple' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'apple'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='apple/generalaphidrisk' title={i18n.t('Apple aphid (Aphis pomi)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apple/generalfireblight' title={i18n.t('Fire Blight (Erwinia amylovora)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />

                                                    <ListItemSyled page='apple/scabascosporeinfection' title={i18n.t('Venturia Inaequalis ascospore infection (Venturia inaequalis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apple/scabcondiainfection' title={i18n.t('Venturia Inaequalis condia infection (Venturia inaequalis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apple/codlingmoth' title={i18n.t('Codling Moth (Cydia pomonella)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />

                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('apricot, plum'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Apricot, Plum')} />
                                                {this.state.currentPageGroup === 'apricot, plum' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'apricot, plum'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='apricotplum/aphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/tarphrinaprunii' title={i18n.t('Taphrina pruni (Taphrina prunii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/apricotplumscabcladosporiumcarpophilum' title={i18n.t('Cladosporium Carpophilum (Cladosporium Carpophilum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/brownrotmonilialaxa' title={i18n.t('Brown rot Monilia laxa (Monilia laxa)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/rustinfection' title={i18n.t('Rust Infection (Tranzschelia pruni-spinosae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/taphrinadeformans' title={i18n.t('Taphrina Deformans (Taphrina deformans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/xanthomonasarboricolainfection' title={i18n.t('Xanthomonas arboricola infection (Xanthomonas arboricola pv. pruni)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/shotholewilsonomycescarpophilus' title={i18n.t('Shot hole Wilsonomyces carpophilus (Clasterosporium carpophilum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='apricotplum/powderymildewrisk' title={i18n.t('Powdery mildew (Sphaerotheca sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('pearandquince'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Pear, Quince')} />
                                                {this.state.currentPageGroup === 'pearandquince' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'pearandquince'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='pearandquince/generalaphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pearandquince/scabventuriapirina' title={i18n.t('Scab Venturia Pirina (Venturia pirina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pearandquince/leafspotfabreasp' title={i18n.t('Leaf spot Fabrea sp. (Mycosphaerella sentina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pearandquince/moniliainfection' title={i18n.t('Monilia Infection (Monilinia laxa)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pearandquince/brownspotinfection' title={i18n.t('Brown Spot Infection (Stemphylium vesicarium)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('raspberry'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Raspberry')} />
                                                {this.state.currentPageGroup === 'raspberry' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'raspberry'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='raspberry/anthracnoseelsinoev' title={i18n.t('Anthracnose Elsinoe V. (Elsinoe veneta)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='raspberry/rust' title={i18n.t('Rust (Phragmidium rubi-idaei)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='raspberry/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('cherry'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Cherry')} />
                                                {this.state.currentPageGroup === 'cherry' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'cherry'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='cherry/monilialaxa' title={i18n.t('Brown rot Monilia laxa (Monilia laxa)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='cherry/leafspot' title={i18n.t('Leaf spot Blumeriella jaapii (Blumeriella jaapii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='cherry/generalcolletotrichum' title={i18n.t('Anthracnose Colletotrichum acutatum (Phoma sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='cherry/shotholewilsonomycescarpophilus' title={i18n.t('Shot hole Wilsonomyces carpophilus (Clasterosporium carpophilum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='cherry/westernflowerthripsfrankliniellaoccidentalis' title={i18n.t('Western Flower thrips Frankliniella occidentalis (Frankliniella occidentalis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='cherry/bacterialcancerpseudomonassyringae' title={i18n.t('Bacterial cancer Pseudomonas syringae (Pseudomonas syringae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('PeachAndNectarine'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Peach, Nectarine')} />
                                                {this.state.currentPageGroup === 'PeachAndNectarine' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'PeachAndNectarine'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='peachnectarine/generalaphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='peachnectarine/powderymildewsphaerothecapannosarisk' title={i18n.t('Powdery Mildew Sphaerotheca Pannosa risk (Sphaerotheca pannosa var. persicae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='peachnectarine/taphrinaleafcurl' title={i18n.t('Taphrina Leaf Curl (Taphrina deformans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='peachnectarine/scabcladosporiumcarpophilumrisk' title={i18n.t('Scab Cladosporium Carpophilum risk (Clasterosporium carpophilum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>

                                            <ListItem button onClick={() => { this.dataGroupHandler('viticulture'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Viticulture')} />
                                                {this.state.currentPageGroup === 'viticulture' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'viticulture'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='viticulture/blackrot' title={i18n.t('Blackrot - Guignardia bidwellii (Guignardia bidwellii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/anthracnose' title={i18n.t('Anthracnose - Elsinoe ampelina (Elsinoe ampelina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildew' title={i18n.t('Viticulture Powdery Mildew (Uncinula necator)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/greymouldrisk' title={i18n.t('Botrytis Bunch Rot/Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildewprimarycycle' title={i18n.t('Downy Mildew Plasmopara viticola  Primary Cycle (Plasmopara viticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildewsecondarycycle' title={i18n.t('Downy Mildew Plasmopara viticola Secondary Cycle (Plasmopara viticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/phomopsisinfection' title={i18n.t('Phomopsis Infection/Phomopsis Cane and Leaf Spot (Phomopsis spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>

                                            <ListItem button onClick={() => { this.dataGroupHandler('tomato'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Tomato')} />
                                                {this.state.currentPageGroup === 'tomato' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'tomato'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='tomato/tomcast' title={i18n.t('Alternaria sp. TomCast (Alternaria solani)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/septoria' title={i18n.t('Septoria Leaf Spot (Septoria lycopersici)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/phythopthora' title={i18n.t('Phythopthora infestans model NoBlight (Phytophthora infestans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/powderymildewrisk' title={i18n.t('Powdery Mildew Risk (Leveillula taurica)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/aphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/anthracnose' title={i18n.t('Anthracnose (Colletotrichum phomoides)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomato/leafmouldcladosporiumfulvum' title={i18n.t('Leaf Mould Cladosporium fulvum (Fulvia fulva)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                             </Collapse>
                                            {/* <ListItem button onClick={() => { this.dataGroupHandler('tomatowarmclimate'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Tomato - Warm Climate, Open Field')} />
                                                {this.state.currentPageGroup === 'tomatowarmclimate' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'tomatowarmclimate'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='tomatowarmclimate/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomatowarmclimate/anthracnose' title={i18n.t('Anthracnose (Colletotrichum phomoides)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomatowarmclimate/leafmouldcladosporiumfulvum' title={i18n.t('Leaf Mould Cladosporium fulvum (Fulvia fulva)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomatowarmclimate/powderymildewrisk' title={i18n.t('Powdery Mildew Risk (Leveillula taurica)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tomatomoderate/aphidrisk' title={i18n.t('Aphid Risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    </List>
                                            </Collapse> */}
                                            <ListItem button onClick={() => { this.dataGroupHandler('potato'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Potato')} />
                                                {this.state.currentPageGroup === 'potato' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'potato'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='potato/generalaphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='potato/phytophthorainfestans' title={i18n.t('Phytophthora Infestans (Phytophthora Infestans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='potato/alternariamodeltomcast' title={i18n.t('Alternaria model TomCast (Alternaria sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='potato/potatoblacklegpectobacteriumaerialinfection' title={i18n.t('Potato black leg Pectobacterium Aerial Infection (Pectobacterium sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='potato/coloradobeetlerisk' title={i18n.t('Colorado Beetle Risk (Leptinotarsa decemlineata)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('oil seed, cabbage'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Cabbage, Oilseed Brassica sp.')} />
                                                {this.state.currentPageGroup === 'oil seed, cabbage' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'oil seed, cabbage'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='oilseedcabbage/mycosphaerellabrassicicola' title={i18n.t('Mycosphaerella Brassicicola (Mycosphaerella brassicicola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='oilseedcabbage/alternariasptomcast' title={i18n.t('Alternaria sp. Tom Cast (Alternaria brassicae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='oilseedcabbage/downymildewinfectionfrey' title={i18n.t('Downy Mildew Infection Frey (Peronospora parasitica)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='oilseedcabbage/sclerotiniainfection' title={i18n.t('Sclerotinia Infection (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='oilseedcabbage/whiteblisteralbugocandida' title={i18n.t('White Blister Albugo candida (Albugo candida)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('carrot, beetroot'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Carrot, Beetroot')} />
                                                {this.state.currentPageGroup === 'carrot, beetroot' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'carrot, beetroot'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='carrotbeetroot/cercospora' title={i18n.t('Beetroot Cercospora (Cercospora beticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='carrotbeetroot/alternariadaucii' title={i18n.t('Alternaria Daucii (Alternaria dauci)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='carrotbeetroot/alternariatomcast' title={i18n.t('Alternaria Tom Cast (Alternaria radicina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('MelonCucumberZucchiniPumpkin'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Melon, Cucumber, Zucchini, Pumpkin')} />
                                                {this.state.currentPageGroup === 'MelonCucumberZucchiniPumpkin' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'MelonCucumberZucchiniPumpkin'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    {/* <ListItemSyled page='meloncucumberzucchinipumpkin/melonpowderymildewrisk' title={i18n.t('Powdery Mildrew cucumber (Pseudoperonospora cubensis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                    <ListItemSyled page='meloncucumberzucchinipumpkin/tomcast' title={i18n.t('Tom Cast (Alternaria sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='meloncucumberzucchinipumpkin/greymouldrisk' title={i18n.t('Grey Mould risk Melon (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='meloncucumberzucchinipumpkin/powderymildewrisk' title={i18n.t('Powdery Mildew Risk Melon (Erysiphe cichoracearum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('walnuts, almonds'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Walnuts, Almonds')} />
                                                {this.state.currentPageGroup === 'walnuts, almonds' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'walnuts, almonds'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='walnutsalmonds/gnomonialeptostyla' title={i18n.t('Gnomonia Leptostyla (Gnomonia leptostyla)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('tobacco'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Tobacco')} />
                                                {this.state.currentPageGroup === 'tobacco' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'tobacco'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='tobacco/bluemoldperonosporatabacinariskandsprayinterval' title={i18n.t('Blue Mold Peronospora tabacina Risk and Spray Interval (Peronospora tabacina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='tobacco/thripsrisk' title={i18n.t('Thrips risk (Frankliniella tritici)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('sunflower'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Sunflower')} />
                                                {this.state.currentPageGroup === 'sunflower' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'sunflower'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='sunflower/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sunflower/alternariaspmodeltomcast' title={i18n.t('Alternaria sp. model TomCast (Alternaria helianthi)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='sunflower/downymildewplasmoparahalstediisoilborne' title={i18n.t('Downy mildew Plasmopara halstedii Soil Borne (Plasmopara halstedii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='sunflower/stemrotsclerotinia' title={i18n.t('Stem rot Sclerotinia (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('strawberry'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Strawberry')} />
                                                {this.state.currentPageGroup === 'strawberry' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'strawberry'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='strawberry/powderymildew' title={i18n.t('Powdery Mildew R (Sphaerotheca macularis sp. fragariae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='strawberry/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='strawberry/leatherberryphytophthoracactorum' title={i18n.t('Leather Berry Phytophthora cactorum (Phytophthora cactorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('pulses'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Pulses')} />
                                                {this.state.currentPageGroup === 'pulses' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'pulses'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='pulses/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pulses/sclerotiniasclerotiorum' title={i18n.t('Sclerotinia sclerotiorum (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pulses/alternariasp' title={i18n.t('Alternaria sp. (Alternaria sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('pomegranate'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Pomegranate')} />
                                                {this.state.currentPageGroup === 'pomegranate' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'pomegranate'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='pomegranate/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='pomegranate/alternariaalternata' title={i18n.t('Alternaria alternata (Alternaria sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='pomegranate/xanthomonasfruitandleafinfection' title={i18n.t('Xanthomonas Fruit and Leaf Infection (Xanthomonas axonopodis pv. punicae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    <ListItemSyled page='pomegranate/xanthomonasblossominfection' title={i18n.t('Xanthomonas Blossom Infection (Xanthomonas axonopodis pv. punicae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('paprika, chilli pepper, eggplants'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Paprika, Chilli Pepper, Eggplants')} />
                                                {this.state.currentPageGroup === 'paprika, chilli pepper, eggplants' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'paprika, chilli pepper, eggplants'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='paprika, chilli pepper, eggplants/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='paprika, chilli pepper, eggplants/phytophthorainfestansmodelfrey' title={i18n.t('Phytophthora infestans model Frey (Phytophthora infestans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('onion'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Onion')} />
                                                {this.state.currentPageGroup === 'onion' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'onion'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='onion/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='onion/leafblightstemphyliumvesicarium' title={i18n.t('Leaf blight Stemphylium vesicarium (Stemphylium vesicarium)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='onion/purpleblotchtomcast' title={i18n.t('Purple Blotch TomCast (Alternaria porii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='onion/botrytiscinerearisk' title={i18n.t('Botrytis cinerea risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='onion/downymildewpdestructormodelmilioncastseverity' title={i18n.t('Downy mildew P. destructor model Milion Cast Severity (Peronospora destructor)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('olive'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Olive')} />
                                                {this.state.currentPageGroup === 'olive' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'olive'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='olive/leafspotspilocaeaoleaginainfection' title={i18n.t('Leaf spot Spilocaea oleagina infection (Spilocaea oleaginea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='olive/anthracnose' title={i18n.t('Anthracnose (Colletotrichum gloeosporioides)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('lettuce'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Lettuce')} />
                                                {this.state.currentPageGroup === 'lettuce' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'lettuce'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='lettuce/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='lettuce/anthracnoselactucae' title={i18n.t('Anthracnose Lactucae (Bremia lactucae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='lettuce/anthracnose' title={i18n.t('Anthracnose (Microdochium panattonianumis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('hazelnut'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Hazelnut')} />
                                                {this.state.currentPageGroup === 'hazelnut' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'hazelnut'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='hazelnut/powderymildewphyllactiniaguttata' title={i18n.t('Powdery Mildew Phyllactinia guttata (Phyllactinia guttata)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('blackberry'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Blackberry')} />
                                                {this.state.currentPageGroup === 'blackberry' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'blackberry'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='blackberry/rust' title={i18n.t('Rust (Phragmidium violaceum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blackberry/anthracnoseelsinoev' title={i18n.t('Anthracnose Elsinoe V. (Elsinoe veneta)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blackberry/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            {user.id_region === 2 &&
                                            <ListItem button onClick={() => { this.dataGroupHandler('blueberry'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Blueberry')} />
                                                {this.state.currentPageGroup === 'blueberry' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            }
                                            <Collapse in={this.state.currentPageGroup === 'blueberry'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='blueberry/anthracnosecolletotrichumacutatum' title={i18n.t('Anthracnose Colletotrichum acutatum (Colletotrichum gloeosporoides)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blueberry/obtrytiscinerea' title={i18n.t('Botrytis cinerea (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blueberry/phomopsisrisk' title={i18n.t('Phomopsis Risk (Phomopsis vaccinii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blueberry/bacterialcancer' title={i18n.t('Bacterial Cancer (Armillaria Mellea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='blueberry/moniliniablight' title={i18n.t('Monilinia Blight (Monilinia sp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                        </List>
                                    </div>
                                            

                                    {this.state.currentPage === 'wheat/fusariumheadblight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Wheat Fusarium Head Blight'}
                                        latinTitle ={'Fusarium spp.'}
                                        diseaseKey={'GeneralFusariumHeadBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/aphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        label={'Pest risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/septorianodorum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf blotch Septoria nodorum'}
                                        latinTitle ={'Septoria nodorum'}
                                        diseaseKey={'Wheat/SeptoriaNodorum'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/septoriatritici' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Septoria Tritici blotch'}
                                        latinTitle ={'Septoria tritici'}
                                        diseaseKey={'Wheat/SeptoriaTritici'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/pucciniaspp' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Rust Puccinia spp.'}
                                        latinTitle ={'Puccinia spp.'}
                                        diseaseKey={'Wheat/PucciniaSpp'}
                                        diseaseGraphType={'twoline'}
                                        param1={'P. Tritici'}
                                        param2={'P. Graminis'}
                                        templeafsol= {true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/powderymildew' && <div className={classes.page}>    
                                    <UniDisease row={this.props.row}
                                        title ={'Wheat Powdery Mildew'}
                                        latinTitle ={'Blumeria graminis'}
                                        diseaseKey={'Wheat/PowderyMildew'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        tempsol= {true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/drechslerateres' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Drechslera Teres'}
                                        latinTitle ={'Pyrenophora teres'}
                                        diseaseKey={'GeneralDrechsleraTeres'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/generaldrechsleragraminea' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Barley stripe/D. graminea'}
                                        latinTitle ={'Pyrenophora graminea'}
                                        diseaseKey={'GeneralDrechsleraGraminea'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                        {this.state.currentPage === 'wheat/takeallgaeumannomycesgraminis' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Take all Gaeumannomyces graminis'}
                                        latinTitle ={'Gaeumannomyces graminis'}
                                        diseaseKey={'GeneralGaeumannomycesGraminis'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        soil={true}/>
                                        </div>}
                                        {/* The key does not give good values   */}
                                    
                                    {this.state.currentPage === 'wheat/ramularia' && <div className={classes.page}> 
                                    < UniDisease row={this.props.row} 
                                        title ={'Ramularia'}
                                        latinTitle ={'Rumularia collo-cygni'}
                                        diseaseKey={'GeneralRamularia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        hum={true}
                                        tempsol={true}
                                    /> 
                                    </div>}

                                    {this.state.currentPage === 'corn/fusariumheadblight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Fusarium Head Blight Corn'}
                                        latinTitle ={'Fusarium Head Blight Corn'}
                                        diseaseKey={'GeneralFusariumHeadBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'corn/leafblighthelminthosporiumturcicum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf Blight Helminthosporium turcicum'}
                                        latinTitle ={'Helminthosporium turcicum'}
                                        diseaseKey={'Corn/LeafBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'South CLB Inf.'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'corn/greyleafspotcercosporasp' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Leaf Spot Cercospora sp.'}
                                        latinTitle ={'Cercospora zeae-maydis'}
                                        diseaseKey={'Corn/GreyLeafSpot'}
                                        favcon={true}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        param={'Risk'}
                                        temphum={true}
                                        daily={true}
                                        />
                                        </div>}

                                    {this.state.currentPage === 'soya/generalsclerotinia' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Stem rot Sclerotinia sclerotiorum'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}

                                    {this.state.currentPage === 'apple/generalaphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Apple aphid'}
                                        latinTitle ={'Aphis pomi'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}

                                    {this.state.currentPage === 'apple/generalfireblight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Fire Blight'}
                                        latinTitle ={'Erwinia amylovora'}
                                        diseaseKey={'GeneralFireblight'}
                                        diseaseGraphType={'oneline'}
                                        minTicksLimit={9}
                                        maxTicksLimit={9}
                                        min={0}
                                        max={400}
                                        label={'Daily infection value'}
                                        legend4={true}
                                        param={'Fireblight DIV'}
                                        templeaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'apple/scabascosporeinfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Venturia Inaequalis ascospore infection'}
                                        latinTitle ={'Venturia inaequalis'}
                                        diseaseKey={'Apple/Scab/AscosporeInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}
                                    {this.state.currentPage === 'apple/scabcondiainfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Venturia Inaequalis condia infection'}
                                        latinTitle ={'Venturia inaequalis'}
                                        diseaseKey={'Apple/Scab/ConidiaInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apple/codlingmoth' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Codling Moth'}
                                        latinTitle ={'Cydia pomonella'}
                                        diseaseKey={'Apple/CodlingMoth'}
                                        param1={'Codling Moth'}
                                        param2={'Codling Moth Adult'}
                                        graph2legende={true}
                                        temphum={true}
                                    /> </div>}
                                    {this.state.currentPage === 'apricotplum/aphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        label={'Pest risk'}
                                        temphum={true}
                                        leaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/tarphrinaprunii' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Taphrina pruni'}
                                        latinTitle ={'Taphrina prunii'}
                                        diseaseKey={'ApricotPlum/TaphrinaPrunii'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        templeaf={true}
                                        prec={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/apricotplumscabcladosporiumcarpophilum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Cladosporium Carpophilum'}
                                        latinTitle ={'Cladosporium carpophilum'}
                                        diseaseKey={'ApricotPlum/Scab/CladosporiumCarpophilum'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/brownrotmonilialaxa' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Brown rot Monilia laxa'}
                                        latinTitle ={'Monilia laxa'}
                                        diseaseKey={'ApricotPlum/MoniliaLaxa'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/rustinfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Rust Infection'}
                                        latinTitle ={'Tranzschelia pruni-spinosae'}
                                        diseaseKey={'GeneralRustInfection'}
                                        diseaseGraphType={'oneline'}
                                        param={'Rust Infection'}
                                        templeaf={true}
                                        leafprec={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/taphrinadeformans' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Taphrina Deformans'}
                                        latinTitle ={'Taphrina deformans'}
                                        diseaseKey={'ApricotPlum/TaphrinaDeformans'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        templeaf={true}
                                        prec={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/xanthomonasarboricolainfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Xanthomonas arboricola infection'}
                                        latinTitle ={'Xanthomonas arboricola pv. pruni'}
                                        diseaseKey={'GeneralJBlossomInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/shotholewilsonomycescarpophilus' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Shot hole Wilsonomyces carpophilus'}
                                        latinTitle ={'Clasterosporium carpophilum'}
                                        diseaseKey={'GeneralShotHoleInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'apricotplum/powderymildewrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Powdery mildew'}
                                        latinTitle ={'Sphaerotheca sp.'}
                                        diseaseKey={'GeneralSphaerothecaPannosaRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Sphaerotheca Risk'}
                                        templeaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'peachnectarine/generalaphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        label={'Pest risk'}
                                        temphum={true}
                                        leaf={true}
                                    />
                                     </div>}
                                    {this.state.currentPage === 'peachnectarine/powderymildewsphaerothecapannosarisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Powdery Mildew Sphaerotheca Pannosa risk'}
                                        latinTitle ={'Sphaerotheca pannosa var. persicae'}
                                        diseaseKey={'GeneralSphaerothecaPannosaRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Sphaerotheca Risk'}
                                        templeaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'peachnectarine/taphrinaleafcurl' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Taphrina Leaf Curl'}
                                        latinTitle ={'Taphrina deformans'}
                                        diseaseKey={'GeneralTaphrinaLeafCurl'}
                                        diseaseGraphType={'oneline'}
                                        param={'Taphrina Risk'}
                                        temp={true}/>
                                        </div>}
                                    {this.state.currentPage === 'peachnectarine/scabcladosporiumcarpophilumrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Scab Cladosporium Carpophilum risk'}
                                        latinTitle ={'Clasterosporium carpophilum'}
                                        diseaseKey={'GeneralCladosporiumCarpophilumRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}/>
                                        </div>}
                                    {/* {this.state.currentPage === 'meloncucumberzucchinipumpkin/melonpowderymildewrisk' && <div className={classes.page}> <MelonCucumberZucchiniPumpkinMelonPowderyMildewRisk row={this.props.row} /> </div>} */}
                                    {this.state.currentPage === 'meloncucumberzucchinipumpkin/tomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Tom Cast'}
                                        latinTitle ={'Alternaria sp.'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'Infection intensity'}
                                        legend3={true}
                                        height={150}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'meloncucumberzucchinipumpkin/greymouldrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk Melon'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'meloncucumberzucchinipumpkin/powderymildewrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Powdery Mildew Risk Melon'}
                                        latinTitle ={'Erysiphe cichoracearum'}
                                        diseaseKey={'MelonCucumberZucchiniPumpkin/MelonPowderyMildewRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Powdery Mildew Risk'}
                                        temphum={true}
                                        solarleaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'walnutsalmonds/gnomonialeptostyla' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Gnomonia Leptostyla'}
                                        latinTitle ={'Gnomonia leptostyla'}
                                        diseaseKey={'WalnutsAlmondsPistachios/GnomoniaLeptostyla'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'carrotbeetroot/cercospora' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Beetroot Cercospora'}
                                        latinTitle ={'Cercospora beticola'}
                                        diseaseKey={'CarrotAndBeetroot/Cercospora'}
                                        dailyinfection={true}
                                        param={'Div Result'}
                                        temphum={true}
                                        leaf={true}
                                    /> 
                                    </div>}
                                    {this.state.currentPage === 'carrotbeetroot/alternariadaucii' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria Daucii'}
                                        latinTitle ={'Alternaria dauci'}
                                        diseaseKey={'CarrotAndBeetroot/AlernariaDauciiAndAlternataInfection'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'carrotbeetroot/alternariatomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria Tom Cast'}
                                        latinTitle ={'Alternaria radicina'}
                                        diseaseKey={'GeneralTomCast'}
                                        legend3={true}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        height={200}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        favcon={true}
                                        param={'Severity DSV'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'oilseedcabbage/alternariasptomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria sp. Tom Cast'}
                                        latinTitle ={'Alternaria brassicae'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        height={200}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'oilseedcabbage/downymildewinfectionfrey' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Downy Mildew Infection Frey'}
                                        latinTitle ={'Peronospora parasitica'}
                                        diseaseKey={'GeneralFreyPhytophthoraInfestans'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'oilseedcabbage/sclerotiniainfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Sclerotinia Infection'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'oilseedcabbage/whiteblisteralbugocandida' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'White Blister Albugo candida'}
                                        latinTitle ={'Albugo candida'}
                                        diseaseKey={'Potato/PhytophthoraInfestans'}
                                        diseaseGraphType={'oneline'}
                                        label={'intensity of infection'}
                                        param={'Infection progress'}
                                        temphum={true}
                                        templeafsol={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'oilseedcabbage/mycosphaerellabrassicicola' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Mycosphaerella Brassicicola'}
                                        latinTitle ={'Monilia laxa'}
                                        diseaseKey={'OilSeedAndCabbage/MycosphaerellaBrassicicola'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                     </div>}

                                    {this.state.currentPage === 'cherry/monilialaxa' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Brown rot Monilia laxa'}
                                        latinTitle ={'Mycosphaerella brassicicola'}
                                        diseaseKey={'Cherry/MoniliaLaxa'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection 1'}
                                        temphum={true}
                                        precleaf={true}/>
                                     </div>}
                                    {this.state.currentPage === 'cherry/leafspot' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Leaf spot Blumeriella jaapii'}
                                        latinTitle ={'Blumeriella jaapii'}
                                        diseaseKey={'Cherry/LeafSpot'}
                                        diseaseGraphType={'oneline'}
                                        param={'Leaf Spot Infection'}
                                        temphum={true}
                                        prec={true}/>
                                     </div>}

                                    {this.state.currentPage === 'cherry/generalcolletotrichum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Anthracnose Colletotrichum acutatum'}
                                        latinTitle ={'Phoma sp.'}
                                        diseaseKey={'GeneralColletotrichum'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/> 
                                    </div>}
                                    {this.state.currentPage === 'cherry/shotholewilsonomycescarpophilus' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Shot hole Wilsonomyces carpophilus'}
                                        latinTitle ={'Clasterosporium carpophilum'}
                                        diseaseKey={'GeneralShotHoleInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'cherry/westernflowerthripsfrankliniellaoccidentalis' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Western Flower thrips Frankliniella occidentalis'}
                                        latinTitle ={'Frankliniella occidentalis'}
                                        diseaseKey={'Cherry/FrankliniellaOccidentalis'}
                                        param={'Frankliniella Occidentalis Risk'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        label={'Pest risk'}
                                        temphum={true}/>
                                        </div>}
                                    {this.state.currentPage === 'cherry/bacterialcancerpseudomonassyringae' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Bacterial cancer Pseudomonas syringae'}
                                        latinTitle ={'Pseudomonas syringae'}
                                        diseaseKey={'Cherry/PseudomonasSyringae'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Risk'}
                                        humleaf={true}
                                        prec={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'viticulture/blackrot' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Blackrot - Guignardia bidwellii'}
                                        latinTitle ={'Guignardia bidwellii'}
                                        diseaseKey={'Viticulture/Blackrot'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/anthracnose' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose - Elsinoe ampelina'}
                                        latinTitle ={'Elsinoe ampelina'}
                                        diseaseKey={'Viticulture/Anthracnose'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildew' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Viticulture Powdery Mildew'}
                                        latinTitle ={'Uncinula necator'}
                                        diseaseKey={'Viticulture/PowderyMildew/AscosporeInfection'}
                                        diseaseGraphType={'twoline'}
                                        param1={'Infection'}
                                        param2={'Infection 2'}
                                        label1={'Ascospore Infection I[%]'}
                                        label2={'Ascospore Infection II[%]'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/greymouldrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Botrytis Bunch Rot/Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildewprimarycycle' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Downy Mildew Plasmopara viticola  Primary Cycle'}
                                        latinTitle ={'Plasmopara viticola'}
                                        diseaseKey={'Viticulture/GrapeVine/DownyMildew/PrimaryCycle'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                    /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildewsecondarycycle' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Downy Mildew Plasmopara viticola Secondary Cycle'}
                                        latinTitle ={'Plasmopara viticola'}
                                        diseaseKey={'Viticulture/GrapeVine/DownyMildew/SecondaryCycle'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/phomopsisinfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Phomopsis Infection/Phomopsis Cane and Leaf Spot'}
                                        latinTitle ={'Phomopsis spp.'}
                                        diseaseKey={'Viticulture/PhomopsisInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        /> </div>}

                                    {this.state.currentPage === 'tomatomoderate/tomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Alternaria sp. TomCast'}
                                        latinTitle ={'Alternaria solani'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'Infection intensity'}
                                        height={150}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        temphum={true}
                                        leaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'tomatomoderate/septoria' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Septoria Leaf Spot'}
                                        latinTitle ={'Septoria lycopersici'}
                                        diseaseKey={'GeneralSeptoriaLeafSpot'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}
                                        /> 
                                        </div>}
                                    {this.state.currentPage === 'tomatomoderate/phythopthora' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Phythopthora infestans model NoBlight'}
                                        latinTitle ={'Phytophthora infestans'}
                                        diseaseKey={'GeneralNoBlightPhythopthoraInfestans'}
                                        param={'Disease Severity'}
                                        label={'Infection intensity'}
                                        legend3={true}
                                        height={150}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        leafprec={true}
                                    /> </div>}
                                    
                                    {this.state.currentPage === 'tomato/powderymildewrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Pyrenophora graminea'}
                                        latinTitle ={'Leveillula taurica'}
                                        diseaseKey={'GeneralTomatoPowderyMildewRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        templeaf={true}/>
                                        </div>}

                                    {this.state.currentPage === 'tomato/aphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Aphid risk'}
                                        label ={ 'Aphid risk [%]'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'tomato/greymouldrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        dateFormat={'DD/MM'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                        
                                    {this.state.currentPage === 'tomato/anthracnose' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose tomato'}
                                        latinTitle ={'Colletotrichum phomoides'}
                                        diseaseKey={'GeneralAnthracnose'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'tomato/leafmouldcladosporiumfulvum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf Mould Cladosporium fulvum'}
                                        latinTitle ={'Fulvia fulva'}
                                        diseaseKey={'GeneralLeafMold'}
                                        diseaseGraphType={'oneline'}
                                        param={'C. Fulvum Risk'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'tomatowarmclimate/powderymildewrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Pyrenophora graminea'}
                                        latinTitle ={'Leveillula taurica'}
                                        diseaseKey={'GeneralTomatoPowderyMildewRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        templeaf={true}
                                        />
                                        </div>}

                                    {this.state.currentPage === 'tomatowarmclimate/aphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Aphid risk'}
                                        label ={ 'Aphid risk [%]'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'tomato/phythopthora' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Phythopthora infestans model NoBlight'}
                                        latinTitle ={'Phytophthora infestans'}
                                        diseaseKey={'GeneralNoBlightPhythopthoraInfestans'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        favcon={true}
                                        param={'Disease Severity'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'tomato/tomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria sp. TomCast'}
                                        latinTitle ={'Alternaria solani'}
                                        diseaseKey={'GeneralTomCast'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        daily={true}
                                        favcon={true}
                                        param={'Severity DSV'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'tomato/septoria' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Septoria Leaf Spot'}
                                        latinTitle ={'Septoria lycopersici'}
                                        diseaseKey={'GeneralSeptoriaLeafSpot'}
                                        diseaseGraphType={'oneline'}
                                        minTicksLimit={100}
                                        maxTicksLimit={100}
                                        min={0}
                                        max={100}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                        
                                    {this.state.currentPage === 'raspberry/anthracnoseelsinoev' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose Elsinoe V.'}
                                        latinTitle ={'Elsinoe veneta'}
                                        diseaseKey={'GeneralElsinoeVeneta'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true} 
                                    /> </div>}
                                    {this.state.currentPage === 'raspberry/rust' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Rust'}
                                        latinTitle ={'Phragmidium rubi-idaei'}
                                        diseaseKey={'GeneralPhragmidiumViolaceum'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true} 
                                    /> </div>}
                                    {this.state.currentPage === 'raspberry/greymouldrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}

                                    {this.state.currentPage === 'pearandquince/generalaphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        label={'Pest risk'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                    /> </div>}
                                    {this.state.currentPage === 'pearandquince/scabventuriapirina' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Scab Venturia Pirina'}
                                        latinTitle ={'Venturia pirina'}
                                        diseaseKey={'Pear/VenturiaPirina'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'pearandquince/leafspotfabreasp' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf spot Fabrea sp.'}
                                        latinTitle ={'Mycosphaerella sentina'}
                                        diseaseKey={'Pear/FabreaLeafSpot'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        templeaf={true}
                                        prec={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'pearandquince/moniliainfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Monilia Infection'}
                                        latinTitle ={'Monilinia laxa'}
                                        diseaseKey={'ApricotPlum/MoniliaLaxa'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'pearandquince/brownspotinfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Brown Spot Infection'}
                                        latinTitle ={'Stemphylium vesicarium'}
                                        diseaseKey={'Pear/StemphyliumVesicarium'}
                                        diseaseGraphType={'threeline'}
                                        param1={'weak Stemphylium v. inf.'}
                                        param2={'mod. Stemphylium v. inf.'}
                                        param3={'sev. Stemphylium v. inf.'}
                                        temphumleaf={true}
                                        />
                                        </div>}

                                    {this.state.currentPage === 'rapseed/pollenbeetleflight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Pollen beetle flight'}
                                        latinTitle ={'Meligethes aeneus'}
                                        diseaseKey={'Rapseed/PollenBeetleFlight'}
                                        param={'Pollen Beetle Flight'}
                                        flight={true}
                                        tempsoil={true}
                                        minTicksLimit={0}
                                        maxTicksLimit={1}
                                        min={0}
                                        max={1}
                                     
                                    /> </div>}
                                    
                                    {this.state.currentPage === 'rapseed/stemrotsclerotiniasclerotiorum' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Stem rot Sclerotinia sclerotiorum Rapeseed'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'rapseed/phomastemcancer' && <div className={classes.page}> 
                                     <UniDisease row={this.props.row}
                                        title ={'Phoma Stem cancer'}
                                        latinTitle ={'Leptosphaeria maculans'}
                                        diseaseKey={'Rapseed/PhomaStemCancer'}
                                        diseaseGraphType={'twoline'}
                                        param1={'Asco Infection'}
                                        param2={'Conidia Infection'}
                                        label1={'Asco Infection'}
                                        label2={'Conidia Infection'}

                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'sugarbeet/cercospora' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Leaf spot'}
                                        latinTitle ={'Cercospora beticola'}
                                        diseaseKey={'SugarBeet/Cercospora'}
                                        param={'Risk'}
                                        favcon={true}
                                        legend2={true}
                                        temphum={true}
                                        leafprec={true}
                                    /> </div>}
                                    
                                    {this.state.currentPage === 'potato/generalaphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        label={'Pest risk'}
                                        param={'Aphid Risk'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}

                                    {this.state.currentPage === 'potato/phytophthorainfestans' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Phytophthora Infestans'}
                                        latinTitle ={'Phytophthora Infestans'}
                                        diseaseKey={'GeneralFreyPhytophthoraInfestans'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}
                                
                                    {this.state.currentPage === 'potato/alternariamodeltomcast' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria model TomCast'}
                                        latinTitle ={'Alternaria sp.'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'Infection intensity'}
                                        legend3={true}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'potato/potatoblacklegpectobacteriumaerialinfection' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Potato black leg Pectobacterium Aerial Infection'}
                                        latinTitle ={'Pectobacterium sp.)'}
                                        diseaseKey={'Potato/PectobacteriumAerialInfection'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'potato/coloradobeetlerisk' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Colorado Beetle Risk'}
                                        latinTitle ={'Leptinotarsa decemlineata)'}
                                        diseaseKey={'Potato/ColoradoBeetleRisk'}
                                        label={'Aphid risk [%]'}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={4}
                                        favcon={true}
                                        param={'Risk'}
                                        temp={true}
                                        />
                                        </div>}

                                    {this.state.currentPage === 'tobacco/bluemoldperonosporatabacinariskandsprayinterval' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Blue Mold Peronospora tabacina Risk and Spray Interval'}
                                        latinTitle ={'Peronospora tabacina'}
                                        diseaseKey={'GeneralNoBlightPhythopthoraInfestans'}
                                        param={'Disease Severity'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        minTicksLimit={6}
                                        maxTicksLimit={6}
                                        min={0}
                                        max={5}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'tobacco/thripsrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Thrips risk'}
                                        latinTitle ={'Frankliniella tritici'}
                                        diseaseKey={'Cherry/FrankliniellaOccidentalis'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        label={'Pest risk'}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Frankliniella Occidentalis Risk'}
                                        temphum={true}
                                        />
                                        </div>}
                                     {this.state.currentPage === 'sunflower/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'sunflower/alternariaspmodeltomcast' && <div className={classes.page}> 
                                     <UniDisease row={this.props.row}
                                        title ={'Alternaria sp. model TomCast'}
                                        latinTitle ={'Alternaria helianthi'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'intensity of infection'}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        height={200}
                                        daily={true}
                                        favcon={true}
                                        legend3={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'sunflower/downymildewplasmoparahalstediisoilborne' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Downy mildew Plasmopara halstedii Soil Borne'}
                                        latinTitle ={'Plasmopara halstedii'}
                                        diseaseKey={'Sunflower/PlasmoparaHalstediiSoilBorne'}
                                        label1={'Air Infection [%]'}
                                        label2={'Soil Infection [%]'}
                                        label3={'Secondary Infection [%]'}
                                        param1={'Air Infection'}
                                        param2={'Soil Infection'}
                                        param3={'Secondary Infection'}
                                        priminf={true}
                                        temphum={true}
                                        soilprecleaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'sunflower/stemrotsclerotinia' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Stem rot Sclerotinia'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphumsoil={true}
                                        leafprec={true}/>
                                        </div>}
                                     {this.state.currentPage === 'strawberry/powderymildew' && <div className={classes.page}> 
                                     <UniDisease row={this.props.row}
                                        title ={'Powdery Mildew R'}
                                        latinTitle ={'Sphaerotheca macularis sp. fragariae'}
                                        diseaseKey={'Strawberry/PowderyMildew/PowderyMildew'}
                                        diseaseGraphType={'oneline'}
                                        param={'Powdery Mildew Risk'}
                                        temphum={true}/>
                                        </div>}
                                     {this.state.currentPage === 'strawberry/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'strawberry/leatherberryphytophthoracactorum' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Leather Berry Phytophthora cactorum'}
                                        latinTitle ={'Phytophthora cactorum'}
                                        diseaseKey={'Strawberry/PhytophthoraCactorum'}
                                        param={'Phytophthora Cactorum Inf.'}
                                        temp={true}
                                        legend1={true}
                                        minTicksLimit={2}
                                        maxTicksLimit={2}
                                        min={0}
                                        max={1}
                                        daily = {true}
                                        height={150}
                                        favcon={true}
                                        prec={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pulses/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pulses/sclerotiniasclerotiorum' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Sclerotinia sclerotiorum'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'pulses/alternariasp' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Alternaria sp.'}
                                        latinTitle ={'Alternaria sp.)'}
                                        diseaseKey={'GeneralTomCast'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        param={'Severity DSV'}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        height={200}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pomegranate/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pomegranate/alternariaalternata' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Alternaria sp.'}
                                        latinTitle ={'Alternaria sp.'}
                                        diseaseKey={'GeneralTomCast'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        param={'Severity DSV'}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        height={200}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pomegranate/xanthomonasfruitandleafinfection' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Xanthomonas Fruit and Leaf Infection'}
                                        latinTitle ={'Xanthomonas axonopodis pv. punicae'}
                                        diseaseKey={'GeneralJFruitAndLeafInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        label1={'Light Infection [%]'}
                                        label2={'Moderate Infection [%]'}
                                        label3={'Severe Infection [%]'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                     {this.state.currentPage === 'pomegranate/xanthomonasblossominfection' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Xanthomonas Blossom Infection'}
                                        latinTitle ={'Xanthomonas axonopodis pv. punicae'}
                                        diseaseKey={'GeneralJBlossomInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        label1={'Light Infection [%]'}
                                        label2={'Moderate Infection [%]'}
                                        label3={'Severe Infection [%]'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'paprika, chilli pepper, eggplants/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'paprika, chilli pepper, eggplants/phytophthorainfestansmodelfrey' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Phytophthora infestans model Frey'}
                                        latinTitle ={'Phytophthora infestans'}
                                        diseaseKey={'GeneralFreyPhytophthoraInfestans'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'onion/greymouldrisk' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'onion/leafblightstemphyliumvesicarium' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf blight Stemphylium vesicarium'}
                                        latinTitle ={'Stemphylium vesicarium'}
                                        diseaseKey={'GeneralStemphyliumVesicarium'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'onion/purpleblotchtomcast' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Purple Blotch TomCast'}
                                        latinTitle ={'Alternaria porii'}
                                        diseaseKey={'GeneralTomCast'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        param={'Severity DSV'}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        heigh={200}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'onion/botrytiscinerearisk' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Botrytis cinerea risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'onion/downymildewpdestructormodelmilioncastseverity' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Downy mildew P. destructor model Milion Cast Severity'}
                                        latinTitle ={'Peronospora destructor'}
                                        diseaseKey={'Onion/MilIonCastSeverity'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        param={'Severity'}
                                        favcon={true}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'olive/leafspotspilocaeaoleaginainfection' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf spot Spilocaea oleagina infection'}
                                        latinTitle ={'Spilocaea oleaginea'}
                                        diseaseKey={'Olive/SpilocaeaOleagina'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}/>
                                        </div>}
                                    {this.state.currentPage === 'olive/anthracnose' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose olive'}
                                        latinTitle ={'Colletotrichum gloeosporioides'}
                                        diseaseKey={'Olive/Anthracnose'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        leafprec={true}
                                        />
                                        </div>}
                                        
                                    {this.state.currentPage === 'lettuce/greymouldrisk' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'lettuce/anthracnoselactucae' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose Lactucae'}
                                        latinTitle ={'Bremia lactucae'}
                                        diseaseKey={'Lettuce/DownyMildewBremiaLactucae'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        />
                                        </div>}
                                     {this.state.currentPage === 'lettuce/anthracnose' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Anthracnose'}
                                        latinTitle ={'Microdochium panattonianumis'}
                                        diseaseKey={'Lettuce/Anthracnose'}
                                        diseaseGraphType={'twoline'}
                                        param1={'Conidia Infection'}
                                        param2={'Microsclerotia Infection'}
                                        label1={'Conidia Infection Anthracnose [%]'}
                                        label2={'Conidia Infection Microsclerotia [%]'}
                                        temphum={true}
                                        leafprec={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'hazelnut/powderymildewphyllactiniaguttata' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Powdery Mildew Phyllactinia guttata'}
                                        latinTitle ={'Phyllactinia guttata'}
                                        diseaseKey={'Hazelnut/PowderyMildew'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        temp={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blackberry/rust' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Rust'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralPhragmidiumViolaceum'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blackberry/anthracnoseelsinoev' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose Elsinoe V.'}
                                        latinTitle ={'Elsinoe veneta'}
                                        diseaseKey={'GeneralElsinoeVeneta'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        />
                                        </div>}
                                     {this.state.currentPage === 'blackberry/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blueberry/anthracnosecolletotrichumacutatum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose Colletotrichum acutatum'}
                                        latinTitle ={'Colletotrichum gloeosporoides'}
                                        diseaseKey={'GeneralColletotrichum'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blueberry/obtrytiscinerea' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Botrytis cinerea'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        param={'Botrytis Risk'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blueberry/phomopsisrisk' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Phomopsis Risk'}
                                        latinTitle ={'Phomopsis vaccinii'}
                                        diseaseKey={'GeneralBerryPhomopsisRisk'}
                                        param={'Phomopsis Risk'}
                                        label={'Risk'}
                                        legend2={true}
                                        temphum={true}
                                        favcon={true}
                                        prec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blueberry/bacterialcancer' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Bacterial Cancer'}
                                        latinTitle ={'Armillaria Mellea'}
                                        diseaseKey={'Berry/BacterialCancer'}
                                        favcon={true}
                                        legend2={true}
                                        param={'Bacterial Cancer Risk'}
                                        temphum={true}
                                        prec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'blueberry/moniliniablight' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Monilinia Blight'}
                                        latinTitle ={'Monilinia sp.'}
                                        diseaseKey={'Blueberry/MoniliniaBlight'}
                                        param={'Infection Severity'}
                                        templeaf={true}
                                        favcon={true}
                                        legend2={true}
                                        />
                                        </div>}
                                        
                                </div>
                                
                            }

                            {this.state.currentData === 'diseasevukoje' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                                    {i18n.t('Crops')}
                                                </ListSubheader>
                                            }></List>
                                            <ListItem button onClick={() => { this.dataGroupHandler('viticulture'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Viticulture')} />
                                                {this.state.currentPageGroup === 'viticulture' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'viticulture'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='viticulture/blackrot' title={i18n.t('Blackrot - Guignardia bidwellii (Guignardia bidwellii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/anthracnose' title={i18n.t('Anthracnose - Elsinoe ampelina (Elsinoe ampelina)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildew' title={i18n.t('Viticulture Powdery Mildew (Uncinula necator)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/greymouldrisk' title={i18n.t('Botrytis Bunch Rot/Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildewprimarycycle' title={i18n.t('Downy Mildew Plasmopara viticola  Primary Cycle (Plasmopara viticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/powderymildewsecondarycycle' title={i18n.t('Downy Mildew Plasmopara viticola Secondary Cycle (Plasmopara viticola)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='viticulture/phomopsisinfection' title={i18n.t('Phomopsis Infection/Phomopsis Cane and Leaf Spot (Phomopsis spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                    </div>

                                    {this.state.currentPage === 'viticulture/blackrot' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Blackrot - Guignardia bidwellii'}
                                        latinTitle ={'Guignardia bidwellii'}
                                        diseaseKey={'Viticulture/Blackrot'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/anthracnose' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Anthracnose - Elsinoe ampelina'}
                                        latinTitle ={'Elsinoe ampelina'}
                                        diseaseKey={'Viticulture/Anthracnose'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildew' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Viticulture Powdery Mildew'}
                                        latinTitle ={'Uncinula necator'}
                                        diseaseKey={'Viticulture/PowderyMildew/AscosporeInfection'}
                                        diseaseGraphType={'twoline'}
                                        param1={'Infection'}
                                        param2={'Infection 2'}
                                        label1={'Ascospore Infection I[%]'}
                                        label2={'Ascospore Infection II[%]'}
                                        temphum={true}
                                        leafprec={true} 
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/greymouldrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Botrytis Bunch Rot/Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildewprimarycycle' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Downy Mildew Plasmopara viticola  Primary Cycle'}
                                        latinTitle ={'Plasmopara viticola'}
                                        diseaseKey={'Viticulture/GrapeVine/DownyMildew/PrimaryCycle'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                    /> </div>}
                                    {this.state.currentPage === 'viticulture/powderymildewsecondarycycle' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Downy Mildew Plasmopara viticola Secondary Cycle'}
                                        latinTitle ={'Plasmopara viticola'}
                                        diseaseKey={'Viticulture/GrapeVine/DownyMildew/SecondaryCycle'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        /> </div>}
                                    {this.state.currentPage === 'viticulture/phomopsisinfection' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Phomopsis Infection/Phomopsis Cane and Leaf Spot'}
                                        latinTitle ={'Phomopsis spp.'}
                                        diseaseKey={'Viticulture/PhomopsisInfection'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}
                                        /> </div>}
                                </div>

                                            
                                
                            }

                                {(this.state.currentData === 'diseasevivagro' || this.state.currentData === 'diseaseskugricanka') &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}
                                            subheader={
                                                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                                                    {i18n.t('Crops')}
                                                </ListSubheader>
                                            }></List>

                                            <ListItem button onClick={() => { this.dataGroupHandler('wheat'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Wheat and Barley')} />
                                                {this.state.currentPageGroup === 'wheat' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'wheat'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='wheat/fusariumheadblight' title={i18n.t('Wheat Fusarium Head Blight (Fusarium spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/aphidrisk' title={i18n.t('Aphid risk (Aphididae)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />

                                                    <ListItemSyled page='wheat/septorianodorum' title={i18n.t('Leaf blotch Septoria nodorum (Septoria nodorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/septoriatritici' title={i18n.t('Septoria Tritici blotch (Septoria tritici)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/pucciniaspp' title={i18n.t('Rust Puccinia spp. (Puccinia spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/powderymildew' title={i18n.t('Wheat Powdery Mildew (Blumeria graminis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='wheat/drechslerateres' title={i18n.t('Drechslera Teres (Pyrenophora teres)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {user.id_region === 2 &&
                                                    <ListItemSyled page='wheat/generaldrechsleragraminea' title={i18n.t('Barley stripe/D. graminea (Pyrenophora graminea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    }
                                                    <ListItemSyled page='wheat/ramularia' title={i18n.t('Ramularia (Ramularia collo-cygni)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='wheat/takeallgaeumannomycesgraminis' title={i18n.t('Take all Gaeumannomyces graminis (Gaeumannomyces graminis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                </List>
                                            </Collapse>
                                            {this.state.currentData === 'diseasevivagro' &&
                                            <div>
                                            <ListItem button onClick={() => { this.dataGroupHandler('corn'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Corn')} />
                                                {this.state.currentPageGroup === 'corn' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'corn'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='corn/fusariumheadblight' title={i18n.t('Fusarium Head Blight Corn (Fusarium spp.)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='corn/leafblighthelminthosporiumturcicum' title={i18n.t('Leaf Blight Helminthosporium turcicum (Helminthosporium turcicum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='corn/greyleafspotcercosporasp' title={i18n.t('Grey Leaf Spot Cercospora sp. (Cercospora zeae-maydis)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('soya'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Soya')} />
                                                {this.state.currentPageGroup === 'soya' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'soya'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='soya/generalsclerotinia' title={i18n.t('Stem rot Sclerotinia sclerotiorum (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('rapseed'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Rapseed / Canola')} />
                                                {this.state.currentPageGroup === 'rapseed' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'rapseed'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='rapseed/pollenbeetleflight' title={i18n.t('Pollen beetle flight (Meligethes aeneus)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='rapseed/stemrotsclerotiniasclerotiorum' title={i18n.t('Stem rot Sclerotinia sclerotiorum Rapeseed (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='rapseed/phomastemcancer' title={i18n.t('Phoma Stem cancer (Leptosphaeria maculans)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                               </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('sunflower'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Sunflower')} />
                                                {this.state.currentPageGroup === 'sunflower' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'sunflower'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='sunflower/greymouldrisk' title={i18n.t('Grey Mould risk (Botrytis cinerea)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sunflower/alternariaspmodeltomcast' title={i18n.t('Alternaria sp. model TomCast (Alternaria helianthi)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sunflower/downymildewplasmoparahalstediisoilborne' title={i18n.t('Downy mildew Plasmopara halstedii Soil Borne (Plasmopara halstedii)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sunflower/stemrotsclerotinia' title={i18n.t('Stem rot Sclerotinia (Sclerotinia sclerotiorum)')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    </List>
                                            </Collapse>
                                    </div>}
                                    </div>
                                    {this.state.currentPage === 'wheat/fusariumheadblight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Wheat Fusarium Head Blight'}
                                        latinTitle ={'Fusarium spp.'}
                                        diseaseKey={'GeneralFusariumHeadBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/aphidrisk' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Aphid risk'}
                                        latinTitle ={'Aphididae'}
                                        diseaseKey={'GeneralAphidRisk'}
                                        diseaseGraphType={'oneline'}
                                        param={'Aphid Risk'}
                                        label={'Pest risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/septorianodorum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf blotch Septoria nodorum'}
                                        latinTitle ={'Septoria nodorum'}
                                        diseaseKey={'Wheat/SeptoriaNodorum'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/septoriatritici' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Septoria Tritici blotch'}
                                        latinTitle ={'Septoria tritici'}
                                        diseaseKey={'Wheat/SeptoriaTritici'}
                                        diseaseGraphType={'threeline'}
                                        param1={'Light Infection'}
                                        param2={'Moderate Infection'}
                                        param3={'Severe Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/pucciniaspp' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Rust Puccinia spp.'}
                                        latinTitle ={'Puccinia spp.'}
                                        diseaseKey={'Wheat/PucciniaSpp'}
                                        diseaseGraphType={'twoline'}
                                        param1={'P. Tritici'}
                                        param2={'P. Graminis'}
                                        templeafsol= {true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/powderymildew' && <div className={classes.page}>    
                                    <UniDisease row={this.props.row}
                                        title ={'Wheat Powdery Mildew'}
                                        latinTitle ={'Blumeria graminis'}
                                        diseaseKey={'Wheat/PowderyMildew'}
                                        diseaseGraphType={'oneline'}
                                        param={'Risk'}
                                        tempsol= {true}
                                        leaf={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/drechslerateres' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Drechslera Teres'}
                                        latinTitle ={'Pyrenophora teres'}
                                        diseaseKey={'GeneralDrechsleraTeres'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'wheat/generaldrechsleragraminea' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Barley stripe/D. graminea'}
                                        latinTitle ={'Pyrenophora graminea'}
                                        diseaseKey={'GeneralDrechsleraGraminea'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    
                                    {this.state.currentPage === 'wheat/ramularia' && <div className={classes.page}> 
                                    < UniDisease row={this.props.row} 
                                        title ={'Ramularia'}
                                        latinTitle ={'Rumularia collo-cygni'}
                                        diseaseKey={'GeneralRamularia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        hum={true}
                                        tempsol={true}
                                    /> 
                                    </div>}
                                    {this.state.currentPage === 'corn/fusariumheadblight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Fusarium Head Blight Corn'}
                                        latinTitle ={'Fusarium Head Blight Corn'}
                                        diseaseKey={'GeneralFusariumHeadBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'corn/leafblighthelminthosporiumturcicum' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Leaf Blight Helminthosporium turcicum'}
                                        latinTitle ={'Helminthosporium turcicum'}
                                        diseaseKey={'Corn/LeafBlight'}
                                        diseaseGraphType={'oneline'}
                                        param={'South CLB Inf.'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'corn/greyleafspotcercosporasp' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Grey Leaf Spot Cercospora sp.'}
                                        latinTitle ={'Cercospora zeae-maydis'}
                                        diseaseKey={'Corn/GreyLeafSpot'}
                                        favcon={true}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        param={'Risk'}
                                        temphum={true}
                                        daily={true}
                                        />
                                        </div>}
                                        {this.state.currentPage === 'soya/generalsclerotinia' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Stem rot Sclerotinia sclerotiorum'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                        {this.state.currentPage === 'rapseed/pollenbeetleflight' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row} 
                                        title ={'Pollen beetle flight'}
                                        latinTitle ={'Meligethes aeneus'}
                                        diseaseKey={'Rapseed/PollenBeetleFlight'}
                                        param={'Pollen Beetle Flight'}
                                        flight={true}
                                        tempsoil={true}
                                        minTicksLimit={0}
                                        maxTicksLimit={1}
                                        min={0}
                                        max={1}
                                    /> </div>}
                                    
                                    {this.state.currentPage === 'rapseed/stemrotsclerotiniasclerotiorum' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Stem rot Sclerotinia sclerotiorum Rapeseed'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                    {this.state.currentPage === 'rapseed/phomastemcancer' && <div className={classes.page}> 
                                     <UniDisease row={this.props.row}
                                        title ={'Phoma Stem cancer'}
                                        latinTitle ={'Leptosphaeria maculans'}
                                        diseaseKey={'Rapseed/PhomaStemCancer'}
                                        diseaseGraphType={'twoline'}
                                        param1={'Asco Infection'}
                                        param2={'Conidia Infection'}
                                        label1={'Asco Infection'}
                                        label2={'Conidia Infection'}
                                        temphum={true}
                                        leafprec={true}/>
                                        </div>}
                                        {this.state.currentPage === 'sunflower/greymouldrisk' && <div className={classes.page}>  
                                     <UniDisease row={this.props.row}
                                        title ={'Grey Mould risk'}
                                        latinTitle ={'Botrytis cinerea'}
                                        diseaseKey={'GeneralGreyMould'}
                                        minTicksLimit={11}
                                        maxTicksLimit={11}
                                        min={0}
                                        max={100}
                                        daily={true}
                                        favcon={true}
                                        param={'Botrytis Risk'}
                                        temphum={true}
                                        leaf={true}/>
                                        </div>}
                                     {this.state.currentPage === 'sunflower/alternariaspmodeltomcast' && <div className={classes.page}> 
                                     <UniDisease row={this.props.row}
                                        title ={'Alternaria sp. model TomCast'}
                                        latinTitle ={'Alternaria helianthi'}
                                        diseaseKey={'GeneralTomCast'}
                                        param={'Severity DSV'}
                                        label={'intensity of infection'}
                                        legend3={true}
                                        minTicksLimit={5}
                                        maxTicksLimit={5}
                                        min={0}
                                        max={5}
                                        height={200}
                                        daily={true}
                                        favcon={true}
                                        temphum={true}
                                        leaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'sunflower/downymildewplasmoparahalstediisoilborne' && <div className={classes.page}> 
                                    <UniDisease row={this.props.row}
                                        title ={'Downy mildew Plasmopara halstedii Soil Borne'}
                                        latinTitle ={'Plasmopara halstedii'}
                                        diseaseKey={'Sunflower/PlasmoparaHalstediiSoilBorne'}
                                        label1={'Air Infection [%]'}
                                        label2={'Soil Infection [%]'}
                                        label3={'Secondary Infection [%]'}
                                        param1={'Air Infection'}
                                        param2={'Soil Infection'}
                                        param3={'Secondary Infection'}
                                        priminf={true}
                                        temphum={true}
                                        soilprecleaf={true}
                                        />
                                        </div>}
                                    {this.state.currentPage === 'sunflower/stemrotsclerotinia' && <div className={classes.page}>  
                                    <UniDisease row={this.props.row}
                                        title ={'Stem rot Sclerotinia'}
                                        latinTitle ={'Sclerotinia sclerotiorum'}
                                        diseaseKey={'GeneralSclerotinia'}
                                        diseaseGraphType={'oneline'}
                                        param={'Apothecia'}
                                        temphumsoil={true}
                                        leafprec={true}/>
                                        </div>}
                                </div>
                                
                            }

                            {this.state.currentData === 'workplanning' &&
                                <div className={classes.container}>
                                    <div className={classes.drawer}>
                                        <List component="nav" aria-labelledby="nested-list-subheader" className={classes.list}>
                                            <ListItem button onClick={() => { this.dataGroupHandler('sowingwindow'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Sowing window')} />
                                                {this.state.currentPageGroup === 'sowingwindow' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'sowingwindow'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='sowingwindow/general' title={i18n.t('General data')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sowingwindow/potato' title={i18n.t('Potato')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sowingwindow/sugarbeet' title={i18n.t('Sugar Beet')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sowingwindow/corn' title={i18n.t('Corn')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sowingwindow/canola' title={i18n.t('Canola')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='sowingwindow/sunflower' title={i18n.t('Sunflower')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('plantprotection'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Plant protection')} />
                                                {this.state.currentPageGroup === 'plantprotection' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'plantprotection'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='plantprotection/general' title={i18n.t('Plant protection')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    {/* <ListItemSyled page='plantprotection/treecrop' title={i18n.t('Tree crop')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='plantprotection/viticulture' title={i18n.t('Viticulture')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='plantprotection/potato' title={i18n.t('Potato')} classes={classes} onPageClick={this.onPageClick} state={this.state} /> */}
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('harvestwindow'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Harvest window')} />
                                                {this.state.currentPageGroup === 'harvestwindow' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'harvestwindow'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='harvestwindow/fruitgrape' title={i18n.t('Fruit, grape')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='harvestwindow/sugarbeetpotato' title={i18n.t('Sugar beet, potato')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='harvestwindow/combinecerals' title={i18n.t('Small cereals')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                    <ListItemSyled page='harvestwindow/haysilage' title={i18n.t('Hay and silage preparation')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('Plant nutrition'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Plant nutrition')} />
                                                {this.state.currentPageGroup === 'Plant nutrition' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'Plant nutrition'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='other/plantnutrition' title={i18n.t('Plant nutrition')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('Field accessibility'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Field accessibility')} />
                                                {this.state.currentPageGroup === 'Field accessibility' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'Field accessibility'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='other/fieldaccessibility' title={i18n.t('Field accessibility')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>
                                            <ListItem button onClick={() => { this.dataGroupHandler('Tillage ability'); }} className={classes.group}>
                                                <ListItemText primary={i18n.t('Tillage ability')} />
                                                {this.state.currentPageGroup === 'Tillage ability' ? <ExpandLess /> : <ExpandMore />}
                                            </ListItem>
                                            <Collapse in={this.state.currentPageGroup === 'Tillage ability'} timeout="auto" unmountOnExit className={classes.collapse}>
                                                <List component="div" disablePadding>
                                                    <ListItemSyled page='other/tillageability' title={i18n.t('Tillage ability')} classes={classes} onPageClick={this.onPageClick} state={this.state} />
                                                </List>
                                            </Collapse>

                                        </List>
                                    </div>
                                    {this.state.currentPage === 'sowingwindow/general' && <div className={classes.page}> <SowingWindowGeneral row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'sowingwindow/potato' && <div className={classes.page}> <SowingWindowPotato row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'sowingwindow/sugarbeet' && <div className={classes.page}> <SowingWindowSugarbeet row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'sowingwindow/corn' && <div className={classes.page}> <SowingWindowCorn row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'sowingwindow/canola' && <div className={classes.page}> <SowingWindowCanola row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'sowingwindow/sunflower' && <div className={classes.page}> <SowingWindowSunflower row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'plantprotection/general' && <div className={classes.page}> <PlantProtectionGeneral row={this.props.row} /> </div>}
                                    {/* {this.state.currentPage === 'plantprotection/treecrop' && <div className={classes.page}> <PlantProtectionTreecrop row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'plantprotection/viticulture' && <div className={classes.page}> <PlantProtectionViticulture row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'plantprotection/potato' && <div className={classes.page}> <PlantProtectionPotato row={this.props.row} /> </div>} */}

                                    {this.state.currentPage === 'harvestwindow/fruitgrape' && <div className={classes.page}> <HarvestWindowFruitGrape row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'harvestwindow/sugarbeetpotato' && <div className={classes.page}> <HarvestWindowSugarbeetPotato row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'harvestwindow/combinecerals' && <div className={classes.page}> <HarvestWindowSmallCereals row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'harvestwindow/haysilage' && <div className={classes.page}> <HarvestWindowHaySillage row={this.props.row} /> </div>}

                                    {this.state.currentPage === 'other/plantnutrition' && <div className={classes.page}> <PlantNutrition row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'other/fieldaccessibility' && <div className={classes.page}> <FieldAccessibility row={this.props.row} /> </div>}
                                    {this.state.currentPage === 'other/tillageability' && <div className={classes.page}> <TillageAbility row={this.props.row} /> </div>}
                                </div>
                            }
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

export default withStyles(styles)(SensorDialog);

function ListItemSyled(props) {
    return (
        <ListItem button onClick={() => { props.onPageClick(props.page) }} className={props.state.currentPage === props.page ? props.classes.list_item : props.classes.pressed}>
            <ListItemText primary={props.title} />
        </ListItem>
    )
}